import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { FuseConfigService } from '@fuse/services/config';
import { Scheme, Theme } from '../../core/config/app.config';
import { tap, take, catchError, of, BehaviorSubject } from 'rxjs';

//@ts-ignore
const colors = require('tailwindcss/colors');

@Injectable({
  providedIn: 'root',
})
export class DominioService {
  constructor(private _httpClient: HttpClient, private _fuseConfigService: FuseConfigService) {}

  private _host: BehaviorSubject<any> = new BehaviorSubject(null);
  private _logo: BehaviorSubject<any> = new BehaviorSubject(null);
  private _titulo: BehaviorSubject<any> = new BehaviorSubject(null);
  private _rotaInicial: BehaviorSubject<any> = new BehaviorSubject(null);
  private _imagemLogin: BehaviorSubject<any> = new BehaviorSubject(null);
  private _blockSindicatos: BehaviorSubject<any> = new BehaviorSubject(null);

  get getHost() {
    return this._host.getValue();
  }

  get getImagens(): { logo: string; login: string } {
    return {
      logo: this._logo.getValue(),
      login: this._imagemLogin.getValue(),
    };
  }

  get getTitulo() {
    return this._titulo.getValue();
  }

  get getBlockSindicatos() {
    return this._blockSindicatos.getValue();
  }

  get getRotaInicial() {
    return this._rotaInicial.getValue();
  }

  getDominio() {
    return this._httpClient.get(`${environment.api}/dominio/get`).pipe(
      take(1),
      catchError((err) => {
        return of(true);
      }),
      tap((resp: any) => {
        this.omitir(resp);
        this._host.next(resp?.host);
        this._logo.next(resp?.logo);
        this._titulo.next(resp?.titulo);
        this._rotaInicial.next(resp?.rotaInicial);
        this._imagemLogin.next(resp?.imagemLogin);
        this._blockSindicatos.next(resp?.blockSindicatos);

        return this.setTemplate(resp);
      })
    );
  }

  omitir(itens: any) {
    const filtrado = Object.keys(itens)?.filter((fil) => fil.startsWith('omitir'));

    filtrado?.map((omitir) => {
      const classes = itens[omitir]?.trim()?.split(';');

      classes?.map((classe) => {
        this.insertCss(`
        .${classe?.trim()}{
          display : none !important ;
        }`);
      });
    });
  }

  setTemplate(dados: any) {
    this.updateImgContent('.iconDominio', dados?.logo);
    this.updateImgContent('imagemLogin', dados?.imagemLogin);

    const params = dados?.layout_color_dark?.split('_').filter((res) => res);

    if (params?.length) {
      this.setTheme(params[1]);
      this.setLayout(params[0]);

      if (params[2] === 'true') {
        this.setScheme('dark');
      }
    }
  }

  setLayout(layout: string): void {
    this._fuseConfigService.config = { layout };
  }

  setScheme(scheme: Scheme): void {
    this._fuseConfigService.config = { scheme };
  }

  setTheme(color: string): void {
    const theme: Theme = color ? color : 'default';

    this.loading(theme);

    this._fuseConfigService.config = { theme };
  }

  loading(teme: any) {
    const valorHexadecimal = this.hexToRgb(colors[teme]['900']);

    const cores = valorHexadecimal.substring(4, valorHexadecimal.length - 1).split(','); // Extrai os valores R, G e B como uma matriz de strings

    const novaCor = cores.map((cor) => {
      let valor = parseInt(cor.trim(), 10); // Converte cada valor para um número inteiro

      // Adiciona dois tons de preto (reduz o valor em 20% cada vez)
      valor = Math.round(valor * 0.4 * 0.4);

      // Verifica se o novo valor é menor que zero e ajusta para zero se necessário
      valor = valor < 0 ? 0 : valor;

      return valor.toString();
    });

    const rgb = `rgb(${novaCor.join(', ')})`; // Retorna a nova cor em formato RGB

    const cssPadrao = `
              body fuse-splash-screen {
                background-color: ${rgb} !important;
              }

              body fuse-splash-screen .spinner > div {
                background-color: ${colors[teme]['900']} !important;
              }`;

    this.insertCss(cssPadrao);
  }

  hexToRgb(hexColor: string) {
    // Removendo o caractere '#' se estiver presente
    const hex = hexColor.replace('#', '');

    // Verificando o comprimento válido do valor hexadecimal
    if (hex.length !== 6) {
      throw new Error('Valor hexadecimal inválido. O valor deve ter 6 dígitos.');
    }

    // Convertendo cada par de dígitos em valores decimais
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Retornando os valores RGB como um array
    return `rgb(${r},${g},${b})`;
  }

  updateImgContent(itemCss: string, base64: string) {
    const cssPadrao = `
                      ${itemCss}{
                        content: url(${base64});
                      }`;

    this.insertCss(cssPadrao);
  }

  insertCss(cssPadrao: any) {
    const styleElemento = document.createElement('style');
    styleElemento.textContent = cssPadrao;

    // Adicione o elemento <style> ao cabeçalho do documento
    document.head.appendChild(styleElemento);
  }
}
