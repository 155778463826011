<div class="w-screen h-screen">
  <div class="flex items-center justify-center h-full w-full flex-wrap lg:flex-nowrap">
    <ng-container *ngIf="!grafico">
      <div *ngFor="let dado of cards; let i = index" class="m-3 w-full md:w-1/3 p-6 bg-white border border-gray-200 shadow-2xl rounded-3xl">
        <div class="flex items-center justify-between mb-4">
          <h5 class="text-2xl pl-5 pt-5 text-gray-500 font-extrabold leading-none">
            {{ returnNome(i) ?? 'Cotação' }}
          </h5>

          <span class="pt-5 text-md text-gray-400 font-semibold">Última cotação : {{ cotacoes[i][0]?.date | date : 'dd/MM/yyyy' }} </span>
        </div>

        <div class="flow-root my-10">
          <ul role="list" class="divide-y divide-gray-200">
            <li *ngFor="let preco of dado" class="py-5">
              <div class="flex items-center space-x-4 px-5">
                <div class="flex-1">
                  <p class="text-2xl font-extrabold truncate" style="color: #0e5f53">
                    {{ preco.title }}
                  </p>
                </div>

                <div class="inline-flex items-center text-2xl font-bold" style="color: #9b9fa3">
                  {{ preco.value | currency : 'BRL' }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
    <!--


===================================== GRAFICO \\\\//// =======================================================


     -->

    <div *ngIf="grafico" matRipple class="flex flex-col items-center justify-center p-6 bg-white h-full w-full mt-4">
      <div class="flex items-center justify-between">
        <div class="flex flex-col">
          <div class="font-bold text-md text-secondary uppercase tracking-wider">Histórico das últimas 10 cotações</div>

          <div class="text-sm text-gray-500 font-medium">Preço médio</div>
        </div>
      </div>

      <div class="flex flex-row w-full items-center">
        <div class="flex flex-col w-full" id="chart">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [xaxis]="chartOptions.xaxis"
            [yaxis]="chartOptions.yaxis"
            [stroke]="chartOptions.stroke"
            [dataLabels]="chartOptions.dataLabels"
            [grid]="chartOptions.grid"
            [colors]="chartOptions.colors"
            [tooltip]="chartOptions.tooltip"
          ></apx-chart>

          <div>Fonte: IFAG - Instituto para o Fortalecimento da Agropecuária de Goiás</div>
        </div>
      </div>
    </div>
  </div>
</div>
